<template>
  <gf-base>
    <gf-card title="Stock Adjustment" subtitle="Manually adjust Product Stocks">
      <template #toolbar>
        <gf-button type="primary" @click="newStockAdjustment">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" cx="9" cy="15" r="6"/>
                  <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
          </template>
          New Record
        </gf-button>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search stock adjustment #, company name" v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="1" label="For Approval"></el-option>
          <el-option :value="2" label="Cancelled"></el-option>
          <el-option :value="3" label="Rejected"></el-option>
          <el-option :value="4" label="Completed"></el-option>
          <el-option :value="5" label="Void"></el-option>
        </el-select>
      </template>
      <gf-table :data="stockAdjustments" @row-click="readStockAdjustment">
        <el-table-column label="Adjustment #" min-width="150" prop="sa_number">
        </el-table-column>
        <el-table-column label="Status" min-width="130" prop="status">
          <template #default="slot">
            <el-dropdown v-if="slot.row.status === 1" trigger="click" @command="statusCommand($event, slot.$index)">
              <gf-label inline pill type="primary">For Approval <i class="el-icon-arrow-down el-icon--right"></i></gf-label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="approve" v-if="isApprover"><i class="el-icon-check" style="color: #67C23A;"></i> Approve</el-dropdown-item>
                <el-dropdown-item command="reject" v-if="isApprover"><i class="el-icon-close" style="color: #F56C6C;"></i> Reject</el-dropdown-item>
                <el-dropdown-item command="cancel"><i class="el-icon-remove" style="color: #F56C6C;"></i> Cancel</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <gf-label inline pill light type="danger" v-if="slot.row.status === 2">Cancelled</gf-label>
            <gf-label inline pill type="danger" v-if="slot.row.status === 3">Rejected</gf-label>
            <gf-label inline pill type="info" v-if="slot.row.status === 4">Completed</gf-label>
            <gf-label inline pill type="warning" v-if="slot.row.status === 5">Void</gf-label>
          </template>
        </el-table-column>
        <el-table-column label="Reason" min-width="150" prop="reason">
        </el-table-column>
        <el-table-column label="Quantity" min-width="150" prop="quantity">
        </el-table-column>
        <el-table-column label="Total Cost" min-width="150" prop="total_cost">
        </el-table-column>
        <el-table-column label="Created" min-width="150" prop="created_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Last Update" min-width="150" prop="updated_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getStockAdjustments"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import StockAdjustmentService from '@/services/v1/StockAdjustment'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      // main
      stockAdjustments: [],

      // ui
      loading: false,
      search: '',
      status: null,
      filter: {},
      isApprover: false,

      // pagination
      pagination: {
        page: {
          size: 25,
          number: 1
        },
        total: 0
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.page.number = 1

      this.getStockAdjustments()
    }, 400)
  },
  methods: {
    newStockAdjustment () {
      this.$router.push({ name: 'stock-adjustment-new' })
    },
    readStockAdjustment (row) {
      this.$router.push({ name: 'stock-adjustment-detail', params: { id: row.id } })
    },
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['sa.status'] = { '=': this.status }
        this.getStockAdjustments()
        return
      }

      delete this.filter['sa.status']
      this.getStockAdjustments()
    },
    async statusCommand (command, index) {
      try {
        const stockAdjustment = this.stockAdjustments[index]
        const saService = new StockAdjustmentService(stockAdjustment.id)
        if (command === 'approve') {
          await saService.approve()
          this.$message.success(`${stockAdjustment.sa_number} has been approved`)
          return
        }

        if (command === 'reject') {
          await saService.reject()
          this.$message.success(`${stockAdjustment.sa_number} has been rejected`)
          return
        }

        if (command === 'cancel') {
          await saService.cancel()
          this.$message.success(`${stockAdjustment.sa_number} has been cancelled`)
          return
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.getStockAdjustments()
      }
    },

    // service fetch
    async getStockAdjustments () {
      try {
        this.loading = true
        const saService = new StockAdjustmentService()
        const result = await saService.list(this.pagination.page, null, this.filter, null)
        this.stockAdjustments = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.isApprover = this.$GetPermission('SA_APPROVAL')

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock Control' },
      { title: 'Stock Adjustments' }
    ])

    this.getStockAdjustments()
  }
}
</script>
